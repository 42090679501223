<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.config") }} - {{ item.code_name }}
    </v-card-title>

    <v-card-text class="error--text font-italic">
      {{ $t("labels.unable_to_ship_floor") }}
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <div class="font-weight-medium purple--text">
            {{ $t("labels.auto_ship_to_warehouse") }}
          </div>
          <div
            v-for="warehouse in warehouseOptions"
            :key="`tw_${warehouse.value}`"
          >
            <v-checkbox
              v-model="toWarehouses"
              class="mt-1 ml-0"
              :value="warehouse.value"
              :label="warehouse.text"
              hide-details
              dense
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="font-weight-medium purple--text">
            {{ $t("labels.receive_orders_from_warehouse") }}
          </div>
          <div
            v-for="warehouse in warehouseOptions"
            :key="`fw_${warehouse.value}`"
          >
            <v-checkbox
              v-model="allowWarehouses"
              class="mt-1 ml-0"
              :value="warehouse.value"
              :label="warehouse.text"
              hide-details
              dense
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="font-weight-medium purple--text">
            {{ $t("labels.auto_ship_to_pos") }}
          </div>
          <div v-for="pos in posOptions" :key="`tp_${pos.value}`">
            <v-checkbox
              v-model="toPoss"
              class="mt-1 ml-0"
              :value="pos.value"
              :label="pos.text"
              hide-details
              dense
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="saveConfig">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "WarehouseConfig",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    toWarehouses: [],
    allowWarehouses: [],
    toPoss: [],
  }),
  computed: {
    warehouseOptions() {
      const { warehouses } = window.me || { warehouses: [] };
      return [...warehouses]
        .filter((w) => w.id !== this.item.id)
        .map((w) => ({
          text: w.code_name,
          value: w.id,
        }));
    },
    posOptions() {
      const { poss } = window.me || { poss: [] };
      return [...poss].map((p) => ({
        text: p.code,
        value: p.id,
      }));
    },
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveConfig() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-warehouse-transfer-config", {
          id_warehouse: this.item.id,
          to_id_warehouses: this.toWarehouses,
          to_id_poss: this.toPoss,
          allow_id_warehouses: this.allowWarehouses,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getConfig() {
      httpClient
        .post("/get-warehouse-transfer-config", { id_warehouse: this.item.id })
        .then(({ data }) => {
          this.toWarehouses = [...data.toItems]
            .filter((item) => !!item.to_id_warehouse)
            .map((item) => item.to_id_warehouse);
          this.toPoss = [...data.toItems]
            .filter((item) => !!item.to_id_pos)
            .map((item) => item.to_id_pos);
          this.allowWarehouses = [...data.allowItems]
            .filter((item) => !!item.allow_id_warehouse)
            .map((item) => item.allow_id_warehouse);
        });
    },
  },
};
</script>

<style scoped></style>
